import React, { useEffect, useState } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    BlockBetween,
    Button,
    Icon
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
import { useForm, FormProvider } from 'react-hook-form';
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormCheckbox from "../../form-components/CheckboxComponent";
import { useFormContext } from 'react-hook-form';
import misc from "../../../api/misc/miscService";
import { ErrorMessage } from "./ErrorMessage";
import { Spinner, Alert } from "reactstrap";
// Define the structure of your form data
interface FormData {
    'comAddressLine1': string;
    'comAddressLine2': string;
    'comCountry': string;
    'comState': string;
    'comDistrict': string;
    'comPincode': string;
    'comCity': string;
    'registeredAddressLine1'?: string;
    'registeredAddressLine2'?: string;
    'registeredCountry'?: string;
    'registeredState'?: string;
    'registeredDistrict'?: string;
    'registeredCity'?: string;
    'registeredPincode'?: string;
}
const ContactInformation_Buyer: React.FC = (props: any) => {
    const methods = useForm({
        mode: 'onChange'
    });
    const { register, handleSubmit, watch, formState: { errors }, setValue, trigger, reset } = useFormContext<FormData>();
    // Watch relevant fields
    const [sameAsCompany, setSameAsCompany] = useState(true);

    // List of fields to watch
    const fieldNames = ['comAddressLine1', 'comAddressLine2', 'comCountry', 'comState', 'comDistrict', 'comCity', 'comPincode'] as const;
    // Watch the fields and construct an object from the array returned
    const watchFieldsArray = watch(fieldNames);
    const watchFields: FormData = fieldNames.reduce((acc, fieldName, index) => {
        acc[fieldName] = watchFieldsArray[index];
        return acc;
    }, {} as FormData);

    useEffect(() => {
        if (sameAsCompany) {
            setValue('registeredAddressLine1', watchFields['comAddressLine1']);
            setValue('registeredAddressLine2', watchFields['comAddressLine2']);
            setValue('registeredCountry', watchFields.comCountry);
            setValue('registeredState', watchFields.comState);
            setValue('registeredDistrict', watchFields.comDistrict);
            setValue('registeredCity', watchFields.comCity);
            setValue('registeredPincode', watchFields.comPincode);
        }
    }, [sameAsCompany, watchFields, setValue]);

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(10);
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showAlert) {
            setCountdown(10);
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        setShowAlert(false);
                        clearInterval(timer);
                        return 10; // Reset the countdown
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showAlert]);
    const submitForm = async (data: any) => {
        setLoading(true)
        try {
            const validation = await misc.onboardValidations({
                phoneNumber: data.adminPhoneNumber,
                adminEmail:  data.adminEmail,
            });
            console.log(validation)
            const errorMessage: ErrorMessage = validation;
            if (errorMessage?.status) {
                const errorsArray: string[] = [];
                for (const key in errorMessage.errors) {
                    if (errorMessage.errors[key].status) {
                        errorsArray.push(errorMessage.errors[key].message);
                    }
                }
                setErrorMessages(errorsArray);
                setShowAlert(true);
                setLoading(false);
            } else {
                setErrorMessages([]);
                setShowAlert(false);
                setLoading(false);
                props.next();
            }
        } catch (error: any) {
            setLoading(false);
            console.log(error.message)
        }
    };

    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDitrictOptions] = useState([]);

    // Watch for changes in the country field
    //const selectedCountry = watch('country');

    // Example city fetching based on country
    const [cities, setCities] = useState([]);

    const getCountries = async () => {
        try {
            const _countries = await misc.getCountries();
            console.log(_countries);
            if (_countries.status) {
                if (_countries.data.length > 0) {
                    const transformedData = _countries.data.map((item: any) => ({
                        value: item.countryId.toString(),
                        label: item.countryName
                    }));
                    setCountryOptions(transformedData)
                }
            }
        } catch (error: any) {
            console.log(error.message)
        }
    }
    const getStates = async (countryId: any) => {
        try {
            const states = await misc.getStatesByCountry(countryId);

            if (states.status) {
                if (states.data.length > 0) {
                    const transformedData = states.data.map((item: any) => ({
                        value: item.stateId.toString(),
                        label: item.stateName
                    }));
                    setStateOptions(transformedData)
                }
            }
        } catch (error: any) {
            console.log(error.message)
        }
    }
    const getDistricts = async (stateID: any) => {
        try {
            const districts = await misc.getDistrictsByState(stateID);
            console.log(districts)
            if (districts.status) {
                if (districts.data.length > 0) {
                    const transformedData = districts.data.map((item: any) => ({
                        value: item.districtId.toString(),
                        label: item.districtName
                    }));
                    setDitrictOptions(transformedData)
                }
            }
        } catch (error: any) {
            console.log(error.message)
        }
    }
    const onCountryChange = (e: any) => {
        console.log(e)
        getStates(e)
    }
    const onStateChange = (e: any) => {
        getDistricts(e)
    }

    useEffect(() => {
        // if (selectedCountry) {
        //     const states: any = getStates(selectedCountry);
        //     // //setCities(fetchedCities);
        // }
        getCountries();
    }, []);


    return (
        <>
            {showAlert && (
                <div className="mb-3 custom-alert">
                    <Alert color="danger" className="alert-icon" toggle={() => setShowAlert(false)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon name="alert-circle" style={{ marginRight: '10px' }} />
                                <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                                    {errorMessages.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                            <span style={{ marginLeft: 'auto', paddingRight: '10px' }}>
                                {countdown}s
                            </span>
                        </div>
                    </Alert>
                </div>
            )}
            <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
                <Row className="gy-4">
                    <Col lg='4'>
                        <FormInput
                            name="contactName"
                            label="Name (Org Admin)"
                            required={false}
                            type="text"
                            placeholder="Enter First Name"
                        />
                    </Col>
                    {/* <Col lg='4'>
                        <FormInput
                            name="lastName"
                            label="Last Name (Admin)"
                            required={false}
                            type="text"
                            placeholder="Enter Last Name"
                        />
                    </Col> */}
                </Row>
                
                <Row className="gy-4 mt-1">
                    <Col lg='4'>
                        <FormInput
                            name="adminEmail"
                            label="Admin Email"
                            required={true}
                            type="email"
                            placeholder="Enter Email address"
                            pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
                        />
                    </Col>
                    <Col lg='4'>
                        <FormInput
                            name="adminPhoneNumber"
                            label="Phone Number"
                            required={true}
                            type="number"
                            placeholder="Enter Admin Phone Number"
                            pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
                        />
                    </Col>
                    <Col lg='4'>
                        <FormInput
                            name="website"
                            label="Website"
                            required={false}
                            type="text"
                            placeholder="Enter Website URL"
                            pattern={/^(https?:\/\/)?(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <h6>Company Address</h6>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg='4'>
                        <FormInput
                            name="comAddressline1"
                            label="Address Line 1"
                            required={true}
                            type="text"
                            placeholder="Street Address Line 1"
                        />
                    </Col>
                    <Col lg='4'>
                        <FormInput
                            name="comAddressLine2"
                            label="Address Line 2"
                            required={true}
                            type="text"
                            placeholder="Street Address Line 2"
                        />
                    </Col>
                    <Col lg='4'>
                        <FormSelect
                            name="comCountry"
                            label="Country"
                            options={countryOptions}
                            required={true}
                            placeholder="-Select-"
                            onChange={(e) => { onCountryChange(e) }}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">

                    <Col lg='4'>
                        <FormSelect
                            name="comState"
                            label="State"
                            options={stateOptions}
                            required={true}
                            placeholder="-Select-"
                            onChange={(e) => onStateChange(e)}
                        />
                    </Col>
                    <Col lg='4'>
                        <FormSelect
                            name="comDistrict"
                            label="District"
                            options={districtOptions}
                            required={true}
                            placeholder="-Select-"
                        />
                    </Col>
                    <Col lg='4'>
                        <FormInput
                            name="comCity"
                            label="City/Town"
                            required={true}
                            type="text"
                            placeholder="Enter City/Town"
                            minLength={3}
                            maxLength={30}
                        />
                    </Col>

                </Row>
                <Row className="mt-3">
                    <Col lg='4'>
                        <FormInput
                            name="comPincode"
                            label="Zip/Pincode"
                            required={true}
                            type="text"
                            placeholder="Enter Zip or pin code"
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <h6>Registerd Address</h6>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <FormCheckbox
                            size={'medium'}
                            name="registeredAddressSameAsCompany"
                            optionLabel="Registered address same as company address"
                            defaultChecked={sameAsCompany}
                            onChange={() => setSameAsCompany(!sameAsCompany)}
                        />
                    </Col>
                </Row>
                {/* Conditional Billing Address Fields */}
                {!sameAsCompany && (
                    <>
                        <Row className="mt-4">
                            <Col lg='4'>
                                <FormInput
                                    name="registeredAddressLine1"
                                    label="Registered Address Line 1"
                                    required={true}
                                    type="text"
                                    placeholder="Registered Street Address Line 1"
                                />
                            </Col>
                            <Col lg='4'>
                                <FormInput
                                    name="RegisteredAddressLine2"
                                    label="Registered Address Line 2"
                                    required={true}
                                    type="text"
                                    placeholder="Registered Street Address Line 2"
                                />
                            </Col>
                            <Col lg='4'>
                                <FormSelect
                                    name="RegisteredCountry"
                                    label="Registered Country"
                                    options={[]}
                                    required={true}
                                    placeholder="-Select-"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">

                            <Col lg='4'>
                                <FormSelect
                                    name="RegisteredState"
                                    label="Registered State"
                                    options={[]}
                                    required={true}
                                    placeholder="-Select-"
                                />
                            </Col>
                            <Col lg='4'>
                                <FormSelect
                                    name="RegisteredDistrict"
                                    label="Registered District"
                                    options={[]}
                                    required={true}
                                    placeholder="-Select-"
                                />
                            </Col>
                            <Col lg='4'>
                                <FormInput
                                    name="RegisteredCity"
                                    label="Registered City/Town"
                                    required={true}
                                    type="text"
                                    placeholder="Enter City/Town"
                                />
                            </Col>

                        </Row>
                        <Row className="mt-3">
                            <Col lg='4'>
                                <FormInput
                                    name="Registered Pincode"
                                    label="Registered Zip/Pincode"
                                    required={true}
                                    type="text"
                                    placeholder="Enter Billing Zip or pin code"
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <div className="actions clearfix">
                    <ul>
                        <li>
                            <Button color="primary" type="submit">
                                {loading ? <Spinner size="sm" color="light" /> : "Next"}
                            </Button>
                        </li>
                        <li>
                            <Button color="primary" onClick={props.prev} className="btn-dim btn btn-secondary">
                                Previous
                            </Button>
                        </li>
                    </ul>
                </div>
            </form>

        </>
    );
};
export default ContactInformation_Buyer;
