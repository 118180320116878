import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from './axiosInstance';
import Cookies from 'js-cookie';

interface ApiCallConfig {
  method: 'get' | 'post' | 'put' | 'delete';
  url: string;
  data?: any;
  params?: Record<string, any>;
}

export async function makeApiCall<T>(config: ApiCallConfig): Promise<T> {
  // Retrieve the access token from the cookie right before the API call
  const accessToken = Cookies.get('access-token');
  console.log("Access Token:", accessToken); 

  try {
    const axiosConfig: AxiosRequestConfig = {
      method: config.method,
      url: config.url,
      data: config.data,
      params: config.params,
      headers: accessToken ? { 'Authorization': `${accessToken}` } : {} 
    //   withCredentials: true
    };
    const response: AxiosResponse<T> = await axiosInstance(axiosConfig);
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in ${config.method.toUpperCase()} Data: ${error.message}`);
  }
}


 