import React, { useState, useEffect } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import { Label, Input, Row, Col } from "reactstrap";
import GeneralInformation_Buyer from "./GeneralInformation_Buyer";
import LegalInformation_Buyer from "./LegalInformation_Buyer";
import ContactInformation_Buyer from "./ContactInformation_Buyer";
import BankAccountDetails from "./BankAccountDetails";
import Documents_Buyer from "./Documents_Buyer";
import Header_Buyer from "./Header_Buyer";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const config = {
  before: Header_Buyer as React.ComponentType<{}> as () => React.ReactElement,
};

const BuyerOnboard: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [orgLogo, setOrgLogo] = useState<string>("");
  const formMethods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, watch } = formMethods;
//   const generalInformation = watch();
const [generalInformation, setGeneralInformation] = useState({})

  useEffect(() => {
    console.log("generalInformation", generalInformation);
  }, [generalInformation]);

  const onSubmit = (data: any) => {
    console.log("Final Data:", data);
  };

  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">
                Organisation Setup{" "}
                <span className="badge bg-info">Party Master</span>
              </BlockTitle>
              <BlockDes>
                Onboard suppliers and buyers to the procurement platform,
                collecting essential organizational, legal, contact, and banking
                information to create comprehensive profiles.
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => {
                  navigate("/party-list");
                }}
              >
                <Icon name="menu-alt-r"></Icon>
                <span>List of registered parties</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                <Steps config={config}>
                  <Step
                    component={(props) => (
                      <GeneralInformation_Buyer
                        next={props.next}
                        orgLogo={orgLogo}
                        setOrgLogo={setOrgLogo}
                        generalInformation = {generalInformation}
                        setGeneralInformation = {setGeneralInformation}
                      />
                    )}
                  />
                  <Step component={LegalInformation_Buyer} />
                  <Step component={ContactInformation_Buyer} />
                  <Step
                    component={(props) => (
                      <BankAccountDetails prev={props.prev} orgLogo={orgLogo} generalInformation = {generalInformation}/>
                    )}
                  />
                  {/* <Step component={Documents_Buyer} /> */}
                </Steps>
              </FormProvider>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default BuyerOnboard;
