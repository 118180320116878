import React, { forwardRef } from "react";
import {
  useFormContext,
  Controller,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import DatePicker from "react-datepicker";
import { Icon } from "../../components/Component";

interface FormDatePickerProps {
  name: string;
  label: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  disabled?: boolean;
  customValidation?: { validate: (selectedDate: Date) => boolean | string };
  scrollableYearDropdown?: boolean;
  showYearDropdown?: boolean;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  label,
  minDate,
  maxDate,
  required,
  placeholder,
  customValidation,
  disabled = false,
  scrollableYearDropdown,
  showYearDropdown,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const errorMessage = getErrorMessage(errors, name);
  const CustomInput = forwardRef<
    HTMLInputElement,
    React.HTMLProps<HTMLInputElement>
  >((props, ref) => (
    <div className="input-group">
      <div className="form-icon form-icon-right">
        <Icon name="calendar"></Icon>
      </div>
      <input
        {...props}
        ref={ref}
        readOnly
        className="form-control"
        placeholder={placeholder}
      />
    </div>
  ));

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <div className="form-control-wrap">
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? "This field is required" : undefined,
            validate: customValidation ? customValidation.validate : undefined,
          }}
          render={({ field }) => (
            <DatePicker
              className="form-control datepicker"
              id={name}
              selected={field.value || null}
              onChange={field.onChange}
              minDate={minDate}
              maxDate={maxDate}
              dateFormat="dd-MMM-yyyy"
              customInput={<CustomInput />}
              disabled={disabled}
              showYearDropdown={showYearDropdown}
              scrollableYearDropdown={scrollableYearDropdown}
            />
          )}
        />
      </div>
      {errorMessage && <p className="mandatory">{errorMessage}</p>}
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  // Split the fieldName into parts to handle nested fields like "blocks[0].name"
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean); // This splits on brackets and dots, and removes empty strings
  let currentError: any = errors; // Start with the whole errors object

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part]; // Navigate deeper into the errors object
    } else {
      return undefined; // No error found for this part
    }
  }

  // If a FieldError is found and it has a message, return it
  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined; // No message found
}

export default FormDatePicker;

// Usage Example
/*
<FormDatePicker 
    name="startDate" 
    label="Start Date" 
    minDate={new Date()} // Today's date as the minimum date
    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Maximum date set to one year from today
    customValidation={{
        validate: (selectedDate) => {
            const endDate = watch("endDate"); // This assumes there's another date picker for the end date
            return endDate > selectedDate || "End date must be later than start date";
        }
    }}
/>

<FormDatePicker 
    name="endDate" 
    label="End Date" 
    customValidation={{
        validate: (selectedDate) => {
            const startDate = watch("startDate");
            return selectedDate > startDate || "End date must be later than start date";
        }
    }}
/>
*/
