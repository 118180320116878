import React, { useState, useEffect, useCallback } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import { useNavigate } from "react-router-dom";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import { useForm, FormProvider } from "react-hook-form";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import FormTextArea from "../form-components/TextAreaComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import Swal from "sweetalert2";
import { Spinner, Alert } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import mastersServices from "../../api/masters/mastersService";
import misc from "../../api/misc/miscService";

interface RowData {
  referenceID: string;
  relatedTo?: {
    material_id: number;
    name: string;
  };
  category: string;
  description?: string;
  status: string;
  createdDate?: string;
  modifiedDate?: string;
  id?: string;
}

const MaterialCategories: React.FC = (props) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, setValue, reset } = formMethods;

  const [modal, setModal] = useState<{ add: boolean; edit: boolean }>({
    add: false,
    edit: false,
  });
  const [currentRowData, setCurrentRowData] = useState<RowData | null>(null);
  const [loading, setLoading] = useState(false);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const submitForm = async (data: any) => {
    if (modal.edit && currentRowData) {
      // Handle edit form submission
      console.log("Editing row:", currentRowData);
      console.log("Form data:", data);
      const addMaterialCategory = await mastersServices.updateMaterialCategory(
        data,
        currentRowData.id
      );
      const { status, message } = addMaterialCategory;
      if (status) {
        setLoading(false);
        Swal.fire("Updated!", "Material Categorie has been updated", "success");
        setModal({ add: false, edit: false });
        reset();
        getMaterialCategories();
      } else {
        setLoading(false);
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } else {
      // Handle add form submission
      setLoading(true);
      const addMaterialCategory = await mastersServices.addMaterialCategory(data);
      const { status, message } = addMaterialCategory;
      if (status) {
        setLoading(false);
        Swal.fire("Created!", "Material Categorie has been created", "success");
        setModal({ add: false, edit: false });
        reset();
        getMaterialCategories();
      } else {
        setLoading(false);
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    }
  };

  const getContructionsMaterialTypes = useCallback(async () => {
    try {
      const matCats = await misc.getContructionsMaterialTypes();
      console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setIndustryTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  const [rowData, setRowData] = useState<RowData[]>();

  const columns: TableColumn<RowData>[] = [
    {
      name: "Category Name",
      width: "250px",
      selector: (row: RowData) => row.category,
      sortable: true,
    },
    {
      name: "Description",
      width: "250px",
      selector: (row: RowData) => row.description!,
      sortable: true,
      cell: (row: RowData) => (
        <div>
          <span
            id={`desc-tooltip-${row.referenceID}`}
          >{`${row.description!.substring(0, 20)}... `}</span>
          <TooltipComponent
            iconClass="info-circle text-info"
            icon="info"
            id={`desc-tooltip-${row.referenceID}`}
            direction="top"
            text={row.description}
            containerClassName={""}
          />
        </div>
      ),
    },
    {
      name: "Internal ID",
      width: "150px",
      selector: (row: RowData) => row.referenceID,
      sortable: true,
    },
    {
      name: "Material Type",
      selector: (row: RowData) => row.relatedTo?.name || "N/A",
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row: RowData) =>
        moment(row.createdDate).format("DD MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: RowData) => row.status,
      sortable: true,
      width: "50",
      cell: (row: RowData) => (
        <span
          className={`tb-status text-${
            row.status === "Active" ? "success" : "danger"
          }`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "",
      width: "90px",
      sortable: false,
      cell: (row: RowData) => (
        <DropdownTrans
          row={row}
          onEdit={() => handleEdit(row)}
          onRemove={() => handleRemove(row)}
        />
      ),
    },
  ];
  const [error, setError] = useState(false);
  const handleEdit = (row: RowData) => {
    console.log(row.relatedTo?.material_id);
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
    setCurrentRowData(row);
    setValue("category", row.category);
    setValue("referenceID", row.referenceID);
    setValue("relatedTo", row.relatedTo?.material_id);
    setValue("description", row.description);

    setModal({ add: false, edit: true });
  };

  const handleRemove = (row: RowData) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Make API call to remove the data
        try {
          const deleteUOM = await mastersServices.removeMaterialCategory(
            row.id
          );
          if (deleteUOM.status) {
            Swal.fire("Deleted!", "Category has been removed.", "success");
            getMaterialCategories();
          } else {
            Swal.fire({
              icon: "error",
              title: "Error in submission",
              text: "Please contact administrator",
              focusConfirm: false,
            });
          }
        } catch (error: any) {
          console.log(error.message);
        }
      }
    });
  };

  const onFormCancel = (e: any) => {
    e.preventDefault();
    setModal({ add: false, edit: false });
    reset();
  };
  const getMaterialCategories = useCallback(async () => {
    try {
      const materialCategories = await mastersServices.getMaterialCategories();

      if (materialCategories.status && materialCategories.data.length > 0) {
        setRowData(materialCategories.data);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);
  useEffect(() => {
    getMaterialCategories();
    getContructionsMaterialTypes();
  }, []);
  return (
    <React.Fragment>
      <Head title="Material Categories" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Material Categories</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button
                    color="primary"
                    className="btn-icon"
                    // onClick={() => setModal({ add: true, edit: false })}
                    onClick={() => {
                      setAlertInfo({
                        type: "", // 'success' or 'error'
                        message: "",
                        errors: [],
                      });
                      setModal({ add: true, edit: false });
                      reset({ relatedTo: "" });
                    }}
                  >
                     {/* <Button color="primary"> */}
                        <Icon name="plus"></Icon>
                        <span style={{marginLeft: "-18%"}}>Add </span>
                      {/* </Button> */}
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <DataTable
              columns={columns}
              data={rowData || []}
              pagination
              striped
              dense
              className="custom-compact-rows"
            />
          </PreviewCard>
        </Block>
      </Content>
      <Modal
        isOpen={modal.add || modal.edit}
        toggle={() => {
          setAlertInfo({
            type: "", // 'success' or 'error'
            message: "",
            errors: [],
          });
          setModal({ add: false, edit: false });
        }}
        className="modal-dialog-centered"
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          <a href="#cancel" onClick={onFormCancel} className="close">
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            {alertInfo.message && (
              <div className="mb-3 mt-1">
                <Alert
                  color={alertInfo.type === "error" ? "danger" : "success"}
                  className="alert-icon"
                >
                  <strong>{alertInfo.message}</strong>
                  <Icon
                    name={
                      alertInfo.type === "error"
                        ? "alert-circle"
                        : "check-circle"
                    }
                  />
                  {alertInfo.errors.length > 0 ? (
                    <ul>
                      {alertInfo.errors.map((err, index) => (
                        <li key={index}>{err}</li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </Alert>
              </div>
            )}

            <h5 className="title">
              {modal.edit ? "Edit Material Category" : "Add Material Category"}
            </h5>
            <div className="mt-4">
              <FormProvider {...formMethods}>
                <form
                  className="content clearfix"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <Row className="gy-4">
                    <Col lg="12">
                      <FormSelect
                        name="relatedTo"
                        label="Material Type"
                        options={industryTypes}
                        required={true}
                        placeholder="-Select-"
                      />
                    </Col>
                    <Col lg="12">
                      <FormInput
                        name="category"
                        label="Category Name"
                        required={true}
                        type="text"
                        minLength={2}
                        maxLength={30}
                        placeholder="Enter Category name"
                      />
                    </Col>
                    <Col lg="12">
                      <FormInput
                        name="referenceID"
                        label="Reference ID (internal)"
                        required={true}
                        type="text"
                        minLength={2}
                        maxLength={30}
                        placeholder="Enter Reference ID"
                      />
                    </Col>
                    <Col lg="12">
                      <FormTextArea
                        name="description"
                        label="Description"
                        required={true}
                        minLength={5}
                        maxLength={100}
                      />
                    </Col>
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="md" type="submit">
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={onFormCancel}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

interface DropdownTransProps {
  row: RowData;
  onEdit: () => void;
  onRemove: () => void;
}

const DropdownTrans: React.FC<DropdownTransProps> = ({
  row,
  onEdit,
  onRemove,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
      >
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul
          className="link-list-plain"
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onEdit();
              }}
            >
              <Icon name="icon ni ni-edit"></Icon>
              View/Edit
            </DropdownItem>
          </li>
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onRemove();
              }}
            >
              <Icon name="icon ni ni-na"></Icon>
              Remove
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default MaterialCategories;
