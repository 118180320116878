import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldErrors, FieldValues } from 'react-hook-form';

interface FormInputProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  column?: string;
  pattern?: RegExp;
  disabled?: boolean;
  customValidation?: { validate: (value: string) => boolean | string };
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  type = 'text',
  required = false,
  minLength,
  maxLength,
  placeholder,
  column,
  pattern,
  customValidation,
  disabled = false,
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const errorMessage = getErrorMessage(errors, name);
  return (
    <div className={column}>
      <div className='form-group'>
        <label className="form-label" htmlFor={name}>{label} 
        {
          required?<span className='mandatory'>*</span>:''
        }
        </label>
        <div className="form-control-wrap">
          <input
            type={type}
            id={name}
            placeholder={placeholder}
            className="form-control"
            disabled={disabled}
            {...register(name, {
              required: required ? 'This field is required' : undefined,
              minLength: minLength ? { value: minLength, message: `Must be at least ${minLength} characters` } : undefined,
              maxLength: maxLength ? { value: maxLength, message: `Cannot be more than ${maxLength} characters` } : undefined,
              pattern: pattern ? { value: pattern, message: 'Invalid format' } : undefined,
              validate: customValidation ? customValidation.validate : undefined
            })}
          />
        </div>
        {errorMessage && <p className='mandatory'>{errorMessage}</p>}
      </div>
    </div>

  );
};

function getErrorMessage<T extends FieldValues>(errors: FieldErrors<T>, fieldName: string): string | undefined {
  // Split the fieldName into parts to handle nested fields like "blocks[0].name"
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean); // This splits on brackets and dots, and removes empty strings
  let currentError: any = errors; // Start with the whole errors object

  for (const part of parts) {
      if (currentError && typeof currentError === 'object') {
          currentError = currentError[part]; // Navigate deeper into the errors object
      } else {
          return undefined; // No error found for this part
      }
  }

  // If a FieldError is found and it has a message, return it
  if (currentError && 'message' in currentError && typeof currentError.message === 'string') {
      return currentError.message;
  }

  return undefined; // No message found
}
export default FormInput;

{/* <FormInput
    name="email"
    label="Email"
    required={true}
    type="email"
    pattern={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
/>
<FormInput
    name="phone"
    label="Phone Number"
    required={true}
    pattern={/^\+?\d{10,15}$/}  // Validates international phone numbers, adjust regex as needed
    customValidation={{
        validate: (value) => value.length > 9 || 'Phone number must be at least 10 digits long'
    }}
/> */}