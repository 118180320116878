import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "reactstrap";
import { useFormContext, Controller, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import mastersServices from "../../../api/masters/mastersService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const BankAccountDetails: React.FC<{ prev: () => void; orgLogo: any; generalInformation:any}> = ({
  prev,
  orgLogo,
  generalInformation
}) => {
  const methods = useForm({
    mode: 'onChange'
});
const { register, handleSubmit, watch, formState: { errors }, setValue, trigger, reset,control } = useFormContext<FormData>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const submitForm = async (data: any) => {
    console.log("generalInformation",generalInformation);
    const updatedData = {
      ...generalInformation,
      ...data
    }
    console.log("Final form data:",updatedData);
    // Add your API submission logic here
    setLoading(true);
    const input = updatedData;
    if(input.partyType === 'Seller'){
      input.categories = input.categories
      input.materials = input.materials
    }
    const output = {
      name: input.orgName,
      category: input.orgType,
      type: input.partyType,
      cin: input.cin,
      incorporationDate: input.incorporationDate,
      pan: input.pan,
      gstin: input.gstin,
      categories: input.categories,
      materials: input.materials,
      contactName: input.contactName,
      // lastName: input.lastName,
      adminEmail: input.adminEmail,
      phoneNumber: input.adminPhoneNumber,
      websiteURL: input.website,
      companyAddress: {
        addressLine1: input.comAddressline1,
        addressLine2: input.comAddressLine2,
        country: input.comCountry,
        state: input.comState,
        district: input.comDistrict,
        city: input.comCity,
        pincode: input.comPincode,
      },
      registeredAddress: {
        addressLine1: input.registeredAddressLine2,
        addressLine2: input.registeredAddressLine2,
        country: input.registeredCountry,
        state: input.registeredState,
        district: input.registeredDistrict,
        city: input.registeredCity,
        pincode: input.registeredPincode,
      },
      bankaccountNumber: input.bankaccountNumber,
      accountHolderName: input.accountName,
      bankName: input.bankName,
      branchName: input.branchName,
      ifcCode: input.ifcCode,
      logoPath: "",
    };
   
    console.log(output);
    try {
      const submitForm = await mastersServices.partyMaster(output);
      console.log(submitForm);
      if (submitForm.status) {
        Swal.fire("Onboarded",`${output.name} has been created`, "success");
        navigate(`/party-list`)
        setLoading(false);
      } else {
        //Swal.fire(`Error: ${submitForm.message.error}`, "error");
        Swal.fire({
          title: "Error!",
          text: `${submitForm.message.error}`,
          icon: "error",
          confirmButtonText: "Cancel",
        });
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      Swal.fire(`Error: ${error.message}`, "error");
    }
  };

  const fileValidation = {
    // required: "Please upload a cheque",
    validate: {
      fileFormat: (files: any) =>
        (files.length &&
          Array.from(files).every((file: any) =>
            /\.(png|jpg|jpeg)$/i.test(file.name)
          )) ||
        "Only PNG and JPG files are allowed!",
    },
  };
  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row>
          <Col lg="4">
            <FormInput
              name="bankaccountNumber"
              label="Bank Account Number"
              required={true}
              type="number"
              placeholder="Bank Account Number"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="accountName"
              label="Account Name"
              required={true}
              type="text"
              placeholder="Enter Account Name"
              // minLength={10}
              // maxLength={20}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankName"
              label="Bank Name"
              required={true}
              type="text"
              placeholder="Enter Bank Name"
              // minLength={5}
              // maxLength={30}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormInput
              name="branchName"
              label="Branch Name"
              required={true}
              type="text"
              placeholder="Enter Branch Name"
              // minLength={3}
              // maxLength={30}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="ifcCode"
              label="IFSC Code"
              required={true}
              type="text"
              placeholder="Enter IFSC Code"
              // minLength={3}
              // maxLength={15}
            />
          </Col>
        {/* </Row>
        <Row className="mt-4"> */}
          <Col lg="4">
            {/* <div className="form-group">
              <div className="form-control-wrap">
                <Controller
                  control={control}
                  name="files"
                  rules={fileValidation}
                  render={({ field, fieldState }) => (
                    <div className="form-file"> */}
                      <FormInput
                        type="file"
                        label="Upload Cheque"
                        name="files"
                          // id="document"
                        //  {...field}
                        // ref={field.ref}
                         placeholder="Enter Document"
                      />
                      {/* {fieldState.error && (
                        <div className="mandatory" style={{ display: "block" }}>
                          {fieldState.error.message}
                        </div>
                      )} */}
                    {/* </div> */}
                  {/* )} */}
                {/* /> */}
              {/* </div>
            </div> */}
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={prev}
                className="btn-dim btn btn-secondary"
                disabled={loading}
              >
                Go Back
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default BankAccountDetails;
