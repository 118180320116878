import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
console.log(process.env.REACT_APP_API_BASE_URL)
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {'Content-Type': 'application/json'},
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/";
      return error;
    }
    if (error.response.status === 409) {
      console.log("axios::::")
      return {
        data: { status: false, message: error.response.data.errors },
      };
    }
    return error;
  }
);
export default axiosInstance;