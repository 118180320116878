import React, { useState, useCallback, useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Row, Col, Button } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import misc from "../../../api/misc/miscService";
import CropperComponent from "./CropperComponent"; // Adjust the path as necessary
import { Icon, RSelect } from "../../../components/Component";
import mastersServices from "../../../api/masters/mastersService";

const GeneralInformation_Buyer: React.FC<{
  orgLogo: any;
  setOrgLogo: (logo: string) => void;
  next: () => void;
  generalInformation:any;
  setGeneralInformation:any
}> = ({ orgLogo, setOrgLogo, next,generalInformation,setGeneralInformation }) => {
 
  const [orgTypeOptions, setOrgTypeOptions] = useState([]);
  const [partyTypeSelected, setPartyTypeSelected] = useState("");
  const [partyType, setPartyTypeOptions] = useState([
    { label: "Buyer", value: "Buyer" },
    { label: "Seller", value: "Seller" },
  ]);
  const [modal, setModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [category, setCategory] = useState([]);
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    // watch,
    reset,
  } = methods;
  const toggleModal = () => setModal(!modal);

  const handleImageSave = (image: string) => {
    setCroppedImage(image);
    toggleModal();
  };

  const getMaterialCategories = async () => {
    try {
      const materialCategories = await mastersServices.getMaterialCategories();

      if (materialCategories.status && materialCategories.data.length > 0) {
        const transformedData = materialCategories.data.map((item: any) => ({
          value: item.id,
          label: item.category,
        }));
        setCategory(transformedData);
        console.log("1111",category,transformedData);
      }
    } catch (error: any) {
      // console.error("Failed to fetch org types:", error.message);
    }
  };

  const getContructionsMaterialTypes = async () => {
    try {
      const matCats = await misc.getContructionsMaterialTypes();
      console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setMaterialTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

  const submitForm = (data: any) => {
    console.log("General Info Data:", data);
    setGeneralInformation(data)
    next();
  };
  const getOrgTypes = async () => {
    try {
      const orgTypes = await misc.getOygTypes();
      if (orgTypes.status && orgTypes.data.length > 0) {
        const transformedData = orgTypes.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.orgtype,
        }));
        setOrgTypeOptions(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  };

 

  useEffect(() => {
    getOrgTypes();
    if (orgLogo) {
      setValue("orgLogo", orgLogo);
      setCroppedImage(orgLogo);
    }
    if(generalInformation){
      console.log("22",generalInformation)
      setValue("orgName", generalInformation.orgName);
      setValue("orgType", generalInformation.orgType);
      setValue("partyType", generalInformation.partyType);
      partyTypeOnChange(generalInformation.partyType);
      setValue("materials", generalInformation.materials);
      setValue("categories", generalInformation.categories);
      // setPartyTypeSelected(generalInformation.partyType);
    }
  }, []);

  const partyTypeOnChange = (value: any) => {
    setPartyTypeSelected(value);
    getMaterialCategories();
    getContructionsMaterialTypes();
  };


  const handleMultiSelectChange = (selectedOptions:any, name:any) => {
    if(name=="categories"){
      const value = selectedOptions.map((option:any) => ({
        categoryID: option.value,
        categoryName: option.label,
      }));
      setValue(name, value);
    }
    else if(name=="materials"){
      const value = selectedOptions.map((option:any) => ({
        materialID: option.value,
        materialName: option.label,
      }));
      setValue(name, value);
    }
   
  };

  useEffect(() => {
    if (croppedImage) {
      setValue("orgLogo", croppedImage);
      setOrgLogo(croppedImage);
    }
    console.log("Partype",partyTypeSelected)
  }, [croppedImage]);

  return (
    <>
      <FormProvider {...methods}>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="orgName"
              label="Organisation Name"
              required={true}
              type="text"
              minLength={2}
              maxLength={30}
              placeholder="Enter Organisation name"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="orgType"
              label="Organisation Type"
              options={orgTypeOptions}
              required={false}
              placeholder="-Select-"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="partyType"
              label="Party Type"
              options={partyType}
              required={true}
              placeholder="-Select-"
              onChange={(value) => {
                partyTypeOnChange(value);
              }}
              // customValidation={{
              //     validate: (value) => value !== 'pending' || 'Pending status is not allowed'
              // }}
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          {partyTypeSelected  == "Seller" && (
            <>
              <Col lg="4">
              <label className="form-label">Material Type</label>
                <RSelect
                  isMulti={true}
                  name="materials"
                  label="Material Type"
                  options={materialTypes}
                  required={true}
                  placeholder="-Select-"
                  onChange={(value:any) => handleMultiSelectChange(value, 'materials')}
                />
              </Col>
              <Col lg="4">
              <label className="form-label">Category</label>
                <RSelect
                  isMulti={true}
                  name="categories"
                  label="categories"
                  options={category}
                  required={true}
                  placeholder="-Select-"
                  onChange={(value:any) => {handleMultiSelectChange(value, 'categories')
                  }}
                />
              </Col>
            </>
          )}
          <Col lg="4" style={{ marginTop: "28px", display: "flex" }}>
            {!croppedImage && (
              <Button color="btn-dim btn" onClick={toggleModal}>
                <span>Upload Logo </span>

                <Icon name="upload-cloud" />
              </Button>
            )}
            {croppedImage && (
              <div style={{ marginTop: "10px" }}>
                <div
                  style={{
                    marginLeft: "25px",
                    height: "60px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src={croppedImage}
                    alt="Cropped"
                    style={{ height: "60px" }}
                  />
                </div>
                <div>
                  <Button color="btn-dim btn" onClick={toggleModal}>
                    <span>Change</span>
                    <Icon
                      name="upload-cloud"
                      className="pointer"
                      onClick={toggleModal}
                    />
                  </Button>
                  <Button
                    color="btn-dim btn"
                    onClick={() => {
                      setCroppedImage(null);
                      setValue("orgLogo", null);
                    }}
                  >
                    <span>Cancle</span>
                    <Icon
                      name="cross"
                      className="pointer"
                      onClick={() => {
                        setCroppedImage(null);
                        setValue("orgLogo", null);
                      }}
                    />
                  </Button>
                </div>
              </div>
            )}
            <FormInput name="orgLogo" type="hidden" label="" />
          </Col>
        </Row>
        <CropperComponent
          modal={modal}
          toggle={toggleModal}
          onSave={handleImageSave}
        />

        <Row className="gy-4 mt-1">
          <Col>
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                console.log("clicked", FormData);
              }}
            >
              Next
            </Button>
          </Col>
        </Row>
      </form>
      </FormProvider>
    </>
  );
};

export default GeneralInformation_Buyer;
