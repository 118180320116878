import React, { useState, useEffect } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    BlockBetween,
    Button,
    Icon
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import { Form, Spinner, Alert } from "reactstrap";
import misc from "../../../api/misc/miscService";
import { ErrorMessage } from "./ErrorMessage";

// type ErrorMessage = {
//     status: boolean;
//     statusCode: string;
//     errors: {
//         [key: string]: {
//             status: boolean;
//             message: string;
//         };
//     };
// };

const LegalInformation_Buyer: React.FC = (props: any) => {
    const methods = useForm({
        mode: 'onChange'
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(10);

    const { register, handleSubmit, watch, formState: { errors }, setValue, trigger, reset } = useFormContext();

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showAlert) {
            setCountdown(10);
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        setShowAlert(false);
                        clearInterval(timer);
                        return 10; // Reset the countdown
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showAlert]);

    const submitForm = async (data: any) => {
        setLoading(true)
        try {
            const validation = await misc.onboardValidations({
                cin: data.cin,
                pan: data.pan,
                gstin: data.gstin,
            });
            console.log(validation)
            // Error Response
            // const errorMessage: ErrorMessage = {
            //     status: true,
            //     statusCode: "",
            //     errors: {
            //         cin: {
            //             status: true,
            //             message: "Duplicate CIN"
            //         },
            //         pan: {
            //             status: false,
            //             message: ""
            //         },
            //         gstin: {
            //             status: true,
            //             message: "Duplicate GSTIN"
            //         }
            //     }
            // };
            const errorMessage: ErrorMessage = validation;
            if (errorMessage?.status) {
                const errorsArray: string[] = [];
                for (const key in errorMessage.errors) {
                    if (errorMessage.errors[key].status) {
                        errorsArray.push(errorMessage.errors[key].message);
                    }
                }
                setErrorMessages(errorsArray);
                setShowAlert(true);
                setLoading(false);
            } else {
                setErrorMessages([]);
                setShowAlert(false);
                setLoading(false);
                props.next();
            }
        } catch (error: any) {
            setLoading(false);
            console.log(error.message)
        }

    };

    return (
        <>
            {showAlert && (
                <div className="mb-3 custom-alert">
                    <Alert color="danger" className="alert-icon" toggle={() => setShowAlert(false)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon name="alert-circle" style={{ marginRight: '10px' }} />
                                <ul style={{ margin: 0, padding: 0, listStyleType: 'none' }}>
                                    {errorMessages.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </div>
                            <span style={{ marginLeft: 'auto', paddingRight: '10px' }}>
                                {countdown}s
                            </span>
                        </div>
                    </Alert>
                </div>
            )}
            <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
                <Row className="gy-4">
                    <Col lg='4'>
                        <FormInput
                            name="cin"
                            label="CIN"
                            required={true}
                            type="text"
                            minLength={2}
                            maxLength={30}
                            placeholder="Enter CIN"
                            pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
                        />
                    </Col>
                    <Col lg='4'>
                        <FormDatePicker
                            name="incorporationDate"
                            label="Incorporation Date"
                            showYearDropdown={true}
                            scrollableYearDropdown={true}
                            maxDate={new Date()} // Today's date as the minimum date
                            //maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Maximum date set to one year from today
                            required={true}
                        />
                    </Col>
                </Row>
                <Row className="gy-4 mt-1">
                    <Col lg='4'>
                        <FormInput
                            name="pan"
                            label="PAN"
                            required={true}
                            type="text"
                            placeholder="Enter PAN"
                            pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                        />
                    </Col>
                    <Col lg='4'>
                        <FormInput
                            name="gstin"
                            label="GSTIN"
                            required={true}
                            type="text"
                            placeholder="Enter GSTIN"
                            pattern={/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/}
                        />
                    </Col>
                </Row>
                <div className="actions clearfix">
                    <ul>
                        <li>
                            <Button color="primary" type="submit">
                                {loading ? <Spinner size="sm" color="light" /> : "Next"}
                            </Button>
                        </li>
                        <li>
                            <Button color="primary" onClick={props.prev} className="btn-dim btn btn-secondary">
                                Previous
                            </Button>
                        </li>
                    </ul>
                </div>
            </form>
        </>
    );
};

export default LegalInformation_Buyer;
