import React, { useEffect } from "react";
import Select, { components } from "react-select";
import {
  useFormContext,
  FieldValues,
  FieldErrors,
  Controller,
} from "react-hook-form";

interface SelectOption {
  value: string;
  label: string;
}

interface FormSelectProps {
  name: string;
  label: string;
  options: SelectOption[];
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  customValidation?: (value: string) => boolean | string;
  maxLength?: number;
  onChange?: (value: string) => void;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  options,
  required = false,
  disabled = false,
  placeholder = "-Select-",
  customValidation,
  maxLength,
  onChange,
}) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    // Register the select input with specific validation rules
    register(name, {
      required: required ? "This field is required" : undefined,
      maxLength: maxLength
        ? {
            value: maxLength,
            message: `Maximum length is ${maxLength} characters`,
          }
        : undefined,
      validate: customValidation
        ? (value: string) => customValidation(value) || "Invalid value"
        : undefined,
    });
  }, [register, name, required, maxLength, customValidation]);

  const handleChange = (selectedOption: any) => {
    const value = (selectedOption as SelectOption)?.value || "";
    setValue(name, value, { shouldValidate: true });
  };

  const errorMessage = getErrorMessage(errors, name);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? "#6576ff" : "#dbdfea",
      borderWidth: state.isFocused ? "1px" : "1px",
      boxShadow: state.isFocused ? "1px 1px 1px 1px #E8EBFF" : "none",
      "&:hover": {
        borderColor: state.isFocused ? "#6576ff" : "#dbdfea",
      },
    }),
  };

  // Custom Dropdown Indicator to replace the default one
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <i className="custom-dropdown-icon" />
      </components.DropdownIndicator>
    );
  };
  //const { control, formState: { errors } } = useFormContext();
  return (
    <div className="form-group">
      <label htmlFor={name} className="form-label">
        {label}
        {required && <span className="mandatory">*</span>}
      </label>
      <div className="form-control-wrap">
        <div className="form-control-select">
          <Controller
            name={name}
            rules={{
              required: required ? "This field is required" : undefined,
              maxLength: maxLength
                ? {
                    value: maxLength,
                    message: `Maximum length is ${maxLength} characters`,
                  }
                : undefined,
              validate: customValidation,
            }}
            render={({ field }) => {
              console.log("Select field value:", field.value);
              return (
                <Select
                  {...field}
                  options={options}
                  value={options.find((c) => c.value === field.value)}
                  onChange={(selectedOption) => {
                    const value = selectedOption ? selectedOption.value : "";
                    field.onChange(value);
                    if (onChange) {
                      onChange(value);
                    }
                  }}
                  placeholder={placeholder}
                  classNamePrefix="react-select"
                  isClearable={!required}
                  isDisabled={disabled} // Ensure disabled prop is passed here
                />
              );
            }}
          />
          {errorMessage && <p className="mandatory">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  // Split the fieldName into parts to handle nested fields like "blocks[0].name"
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean); // This splits on brackets and dots, and removes empty strings
  let currentError: any = errors; // Start with the whole errors object

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part]; // Navigate deeper into the errors object
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default FormSelect;
